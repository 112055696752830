<template>
    <v-row>         
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="500"
                
            >
                    <v-card-title
                        class="text-subtitle-2 pa-2 white--text d-flex justify-center primary"
                    >
                        <v-spacer></v-spacer>
                        <span>Print / View Reports</span>
                        <v-spacer></v-spacer>                   
                    </v-card-title>

                    <v-card-text
                        style="height: 60vh; overflow-y: auto;"
                    >
                        <v-list two-line>
                            <v-list-item-group
                                active-class="primary--text"
                                v-model="selectedReport"
                            >
                                <template v-for="(item, index) in items">                                   
                                    <v-list-item 
                                        :key="item.title"
                                        @click="displayReport(item.report)"
                                    >
                                       <template v-slot:default="{ active }">
                                           <v-list-item-content>
                                               <v-list-item-title>
                                                {{ item.title }}
                                               </v-list-item-title>
                                               <v-list-item-subtitle
                                                    class="text--primary"
                                               >
                                                {{ item.subtitle }}
                                               </v-list-item-subtitle>
                                           </v-list-item-content>
                                           <v-list-item-action>
                                               <v-icon
                                                    v-if="!active"
                                                    color="grey lighten-1"
                                               >
                                                   mdi-printer-search
                                               </v-icon>
                                               <v-icon
                                                    v-else
                                                    color="primary"
                                               >
                                                   mdi-printer-search
                                               </v-icon>
                                           </v-list-item-action>
                                       </template>
                                   </v-list-item>

                                   <v-divider
                                        v-if="index < items.length -1"
                                        :key="index"
                                   ></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>

                    <v-overlay
                        absolute
                        :value="overlay"
                        color="grey"
                        opacity="0.6"
                   >
                        <v-progress-circular 
                            v-if="progress"
                            indeterminate 
                            size="64"
                            color="primary"
                        ></v-progress-circular>

                        <v-expand-transition>                            
                            <v-card
                                max-width="300"
                                class="mx-auto"
                                transition="scroll-y-transition"
                                v-show="expand"
                                light
                            >
                                 
                                <v-card-title
                                    class="d-flex justify-center primary white--text"
                                >
                                    <span>{{ reportOption }}</span>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text                
                                        x-small
                                        color="white"
                                        @click="close"
                                    >
                                        <v-icon>mdi-window-close</v-icon>
                                    </v-btn> 
                                </v-card-title>

                                
                                <v-card-text class="mt-3" v-if="displayAcademicPeriod">
                                    <v-autocomplete
                                        
                                        v-model="academicPeriod"
                                        :items="academicPeriods"
                                        item-text="text"
                                        item-value="value"                                                
                                        dense
                                        filled
                                        color="blue-grey lighten-2"
                                        prepend-icon="mdi-calendar"
                                        hide-details=""
                                    ></v-autocomplete>                                        

                                </v-card-text>

                                <div style="max-height: 400px;  overflow-y: auto">    
                                    <v-list 
                                        light
                                    >
                                        <v-list-group
                                            v-for="item in formClasses"
                                            :key = "item.title"
                                            v-model="item.active"
                                            :prepend-icon="item.action"
                                            no-action
                                        >
                                            <template v-slot:activator>
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ item.title }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </template>
                                            <v-list-item
                                                v-for="subItem in item.items"
                                                :key="subItem.title"
                                                @click="setSelectedClass(subItem.title)"                                       
                                            >
                                                <v-list-item-content>
                                                    <v-list-item-title>
                                                        {{ subItem.title }}
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list-group>
                                    </v-list>
                                </div>

                                <v-card-actions
                                    v-if="cardActions"
                                >
                                    <v-btn
                                        block
                                        text
                                        outlined
                                        @click="showReport"
                                    >
                                        Display Report
                                    </v-btn>
                                </v-card-actions>

                                <v-overlay
                                    absolute
                                    :value="overlayReportOption"
                                    color="white"
                                    opacity="0.6"
                                >
                                    <v-progress-circular 
                                        indeterminate 
                                        size="64"
                                        color="primary"
                                    ></v-progress-circular>
                                </v-overlay> 

                            </v-card> 
                        </v-expand-transition>

                   </v-overlay>    
                </v-card>
        </v-col>

        <!-- Report Viewer -->
        <v-dialog
            v-model="reportViewer"
            max-width="90vw"
            persistent

        >
            <v-card
               height="90vh"
               style="position:relative;" 
            >                
                <iframe 
                    ref="pdf" 
                    style="width:100%; height:90%" 
                    class="report-viewer"  
                    :src="src"
                ></iframe>              
                <v-overlay
                    absolute
                    :value="reportViewerOverlay"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="downloadButton"
                        color="primary"
                        class="mr-4"
                        @click="downloadSpreadsheet"
                    >
                        <v-icon left >mdi-cloud-download</v-icon>
                        Download Spreadsheet
                    </v-btn>
                    <v-btn
                        depressed
                        class="mr-4"
                        @click="closeReport"
                    >
                        <v-icon left >mdi-close</v-icon>
                        Close
                    </v-btn>
                </v-card-actions> 
            </v-card>
        </v-dialog> 
    </v-row>    
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    created(){
        this.initialize()
    },
    
    data: () => ({
        selectedReport: null,
        items: [
            { 
                title: 'Report Cards by Class', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'report-card-class', 
            },            
            { 
                title: 'Class Mark Sheets', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'marksheet-class', 
            },            
            { 
                title: 'Class Lists', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'class-list', 
            },
            { 
                title: 'Student Totals by Class', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'class-population', 
            },
            // { 
            //     title: 'Device / Internet Report', 
            //     subtitle: '', 
            //     icon: 'mdi-printer-search',
            //     report: 'device-internet', 
            // },           
            { 
                title: 'School Feeding Report', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'school-feeding' 
            },              
            { 
                title: 'Student Contact Information', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'contact-information' 
            },
             { 
                title: 'ASR - Student Ethnic Group Statistics', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'ethnic-group-statistics' 
            },
            { 
                title: 'ASR - Student Religion Statistics', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'religion-statistics' 
            },
            { 
                title: 'ASR - Student Age Statistics', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'age-statistics' 
            },            
            { 
                title: 'Student Health Data', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'health-data' 
            },            
            { 
                title: 'Foreign Students', 
                subtitle: '', 
                icon: 'mdi-printer-search',
                report: 'foreign-students' 
            },            
        ],
        overlay: false,
        loading: false,
        expand: false,
        expandSelectStudent: false,
        formClasses: [],
        formClassesFilter: [],
        academicPeriod: '',        
        academicPeriods: [],        
        reportViewer: false,
        reportViewerOverlay: false,
        src: null,
        reportSelected: null,
        formLevelFilter: null,
        displayAcademicPeriod: false,
        students: [],
        selectedStudent: null,
        headersStudents: [],
        searchStudent: '',
        loadingStudents: false,
        activeFormLevel: '',
        listGroup: false,
        formLevels: [],
        selectedFormLevel: null,
        reportOption: 'Select Class',
        cardActions: false,
        selectedFormClass: null,
        downloadButton: false,
        overlayReportOption: false,
        progress: false,
    }),


    methods:{
        ...mapMutations({
            setAcademicYearId: 'printViewReports/setAcademicYearId',
            setFormClassId: 'printViewReports/setFormClassId',
        }),

        ...mapActions({
            getFormClassesList: 'printViewReports/getFormClassesList',
            getMarkSheetTerms: 'printViewReports/getMarkSheetTerms',
            getReportCardTerms: 'printViewReports/getReportCardTerms',
            getStudents: 'common/getCurrentStudents',
            getClassListYears: 'printViewReports/getClassListYears',
            downloadASR: 'printViewReports/downloadASR',
            getSpreadsheet: 'printViewReports/downloadSpreadsheet',
        }),

        initialize () {
            // this.overlay = true;
            // this.loading = true;
            // this.mapFormClasses();
            
        },

        async mapFormClasses () {
            this.formClasses = [];
            console.log('academic period:', this.academicPeriod)
            try {
                const { data } = await this.getFormClassesList();
                console.log(data);
                if(data.length > 0){                    
                    let form1Classes = [];
                    let form2Classes = [];
                    let form3Classes = [];
                    let form4Classes = [];
                    let form5Classes = [];
                    let form6Classes = []; 
                    data.forEach(record => {
                        let formClassId = record.form_class_id ? record.form_class_id : record.id;
                        switch(record.form_level){
                            case "1":
                            form1Classes.push({
                                title: formClassId
                            });
                            break;
                            case "2":
                            form2Classes.push({
                                title: formClassId
                            });
                            break;
                            case "3":
                            form3Classes.push({
                                title: formClassId
                            });
                            break;
                            case "4":
                            form4Classes.push({
                                title: formClassId
                            });
                            break;
                            case "5":
                            form5Classes.push({
                                title: formClassId
                            });
                            break;
                            case "6":
                            form6Classes.push({
                                title: formClassId
                            });
                            break;
                        }                
                    });
                    this.formClasses.push({
                        title: 'Form 1',
                        action: 'mdi-school',                
                        items: form1Classes,
                        formLevel: 1
                    });
                    this.formClasses.push({
                        title: 'Form 2',
                        action: 'mdi-school',                
                        items: form2Classes,
                        formLevel: 2,
                    });
                    this.formClasses.push({
                        title: 'Form 3',
                        action: 'mdi-school',                
                        items: form3Classes,
                        formLevel: 3,
                    });
                    this.formClasses.push({
                        title: 'Form 4',
                        action: 'mdi-school',                
                        items: form4Classes,
                        formLevel: 4,
                    });
                    this.formClasses.push({
                        title: 'Form 5',
                        action: 'mdi-school',                
                        items: form5Classes,
                        formLevel: 5,
                    });
                    if(form6Classes.length > 0){
                        this.formClasses.push({
                            title: 'Form 6',
                            action: 'mdi-school',                
                            items: form6Classes,
                            formLevel: 6,
                        }); 
                    }
                                                              
                }                
            } catch (error) {
                console.log(error);
            }
        },

      

        setFormLevels () {
            this.listGroup = false;            
            if(this.formLevels.length == 0){                
                this.formClasses.forEach(value => {
                    this.formLevels.push({
                        icon: 'mdi-school',
                        text: 'Form ' + value.formLevel,
                        value: value.formLevel
                    })
                })
            }
        },

        async displayReport(report){
            console.log('report: ', report);
            this.reportSelected = report;
            this.reportOption = "Select Class";
            this.cardActions = false;
            this.downloadButton = false;
            switch(report){
                case 'report-card-class':
                    this.displayAcademicPeriod = true;
                    this.overlay = true;
                    this.progress = true;
                    this.setReportCardTerms();   
                    this.setAcademicYearId(null);
                    await this.mapFormClasses(); 
                    this.progress = false;
                    setTimeout(() => this.expand = true, 700);              
                    break
                case 'marksheet-class':
                    this.displayAcademicPeriod = true;
                    this.overlay = true;
                    this.progress = true;
                    this.markSheetMenu();                   
                    this.setAcademicYearId(null);
                    await this.mapFormClasses();  
                    this.progress = false;
                    setTimeout(() => this.expand = true, 700);                                   
                    break
                case 'class-list':
                    this.overlay = true;
                    this.progress = true;
                    await this.setClassListYears();
                    this.setAcademicYearId(this.academicPeriod);
                    await this.mapFormClasses(); 
                    this.progress = false;
                    this.downloadButton = true;
                    this.displayAcademicPeriod = true;
                    setTimeout(() => this.expand = true, 700);    
                    break
                case 'class-population':
                    this.displayAcademicPeriod = false;
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc();
                    break
                case 'contact-information':
                    this.overlay = true;
                    this.progress = true;
                    await this.setClassListYears();
                    this.setAcademicYearId(this.academicPeriod);
                    await this.mapFormClasses(); 
                    this.progress = false;
                    this.displayAcademicPeriod = false;
                    setTimeout(() => this.expand = true, 700); 
                    break;
                 case 'school-feeding':
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc();  
                    break; 
                case 'device-internet':
                    this.setFormLevels();
                    this.overlay = true;
                    this.expand = true;                    
                    break;
                case 'ethnic-group-statistics':
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc();                  
                    break;                   
                case 'religion-statistics':
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc();                 
                    break;
                case 'age-statistics':
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc();                 
                    break;                         
                case 'health-data':
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc();                 
                    break;                         
                case 'foreign-students':
                    this.reportViewer = true;
                    this.reportViewerOverlay = true;
                    this.setReportSrc();                 
                    break;                         
            }
            
        },

        async markSheetMenu(){
            this.overlayReportOption = true;
            try {
                const { data } = await this.getMarkSheetTerms();
                let count = 0;
                data.forEach((record,index) => {
                    this.academicPeriods.push({
                        text: record.academic_year + " Term " + record.term,
                        value: record.academic_term_id
                    });
                    count = index;
                })
                this.academicPeriod = data[count].academic_term_id;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlayReportOption = false;
        },

        async setReportCardTerms () {   
            try {
                const { data } = await this.getReportCardTerms();                
                data.forEach(record => {
                    this.academicPeriods.push({
                        text: record.academic_year + " Term " + record.term,
                        value: record.academic_term_id
                    });                                       
                })
                this.academicPeriod = data[0].academic_term_id;
            } catch (error) {
                if(error.response) console.log(error.reponse);
                else console.log(error);
            }

        },

        async setClassListYears () {
            this.overlayReportOption = true;
            try {
                const { data } = await this.getClassListYears();
                this.academicPeriods = new Array();
                data.forEach(value => {
                    this.academicPeriods.push({
                        text: value.start + '-' + value.end,
                        value: value.academic_year_id

                    })
                })
                this.academicPeriod = this.academicPeriods[0].value;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.overlayReportOption = false;
        },

        setSelectedClass(formClass){
            this.selectedFormClass = formClass;
            this.reportViewer = true;
            this.reportViewerOverlay = true;
            this.setReportSrc();         
                      
        },

        showReport () {
            this.reportViewer = true;
            this.reportViewerOverlay = true;
            this.setReportSrc();   
        },

        setSelectedFormLevel (formLevel) {
            this.selectedFormLevel = formLevel;
            this.reportViewer = true;
            this.reportViewerOverlay = true;
            this.setReportSrc(); 
        },

        setReportSrc () {
            this.$nextTick( function () {
                this.$refs.pdf.onload = () => {
                    console.log('report is loaded');
                    this.reportViewerOverlay = false;
                }
            })

            switch(this.reportSelected){
                case 'report-card-class':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/report-card/"+this.academicPeriod+"/"+this.selectedFormClass;
                    break;
                case 'marksheet-class':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/mark-sheet/"+this.academicPeriod+"/"+this.selectedFormClass;
                    break;
                case 'class-list':  
                    this.setAcademicYearId(this.academicPeriod);
                    this.setFormClassId(this.selectedFormClass);                  
                    this.src = process.env.VUE_APP_API_URI + "/api/class-list/"+this.selectedFormClass+"/"+this.academicPeriod;
                    break;
                case 'marksheet-subject-choice-class':
                    this.src = process.env.VUE_APP_API_URI + 
                    "/api/mark-sheet-subject-choice/"+this.selectedFormClass;
                    break; 
                case 'marksheet-subject-choice-student':
                    this.src = process.env.VUE_APP_API_URI + 
                    "/api/mark-sheet-subject-choice/"+this.selectedFormClassformClass+"/"+this.selectedStudent;
                    break;
                case 'registration-status':
                    this.src = process.env.VUE_APP_API_URI + 
                    "/api/registration-status/1";
                    break;
                case 'contact-information':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/student-contact/"+this.selectedFormClass;
                    break;        
                case 'school-feeding':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/school-feeding/" + this.academicPeriod;
                    break;        
                case 'device-internet':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/device-internet/"+this.selectedFormLevel;
                    break;
                case 'ethnic-group-statistics':
                    this.src = process.env.VUE_APP_API_URI + "/api/ethnic-group-statistics";
                    break;         
                case 'religion-statistics':
                    this.src = process.env.VUE_APP_API_URI + "/api/religious-group-statistics";
                    break;         
                case 'age-statistics':
                    this.src = process.env.VUE_APP_API_URI + "/api/student-age-statistics";
                    break;          
                case 'health-data':
                    this.src = process.env.VUE_APP_API_URI + "/api/student-health";
                    break;          
                case 'foreign-students':
                    this.src = process.env.VUE_APP_API_URI + "/api/foreign-students";
                    break; 
                case 'class-population':                    
                    this.src = process.env.VUE_APP_API_URI + "/api/student-class-population";
                    break;             
            }  
        },

        close(){
            this.expandSelectStudent = false;
            this.$nextTick(() => {
                this.expand = false;
                this.selectedReport = null;
                setTimeout(() => { this.overlay = false}, 1000);
            })
            
        },

        closeReport(){
             if(
                this.reportSelected === 'registration-status' ||
                this.reportSelected === 'school-feeding'
            ){
                this.overlay = false;
            }
            this.reportViewer = false;
            this.src = '';
        },

        setSelectedStudent (item) {
            this.selectedStudent = item.student_id;
            this.setSelectedClass(item.form_class_id);
        },

        async setStudents () {
            this.headersStudents = [
                { text: 'ID', value: 'student_id', width: 100 },
                { text: 'Name', value: 'name' },
                { text: 'Form Class', value: 'form_class_id', width: 100 },
                { text: '', value: 'actions', width: 100, sortable: false }
            ];

            try {
                const { data } = await this.getStudents();
                this.students = data.map(value => {
                    value.name = value.first_name + ' ' + value.last_name;
                    return value;
                })
                if(this.formLevelFilter){
                    this.students = data.filter(value => {
                        if(value.form_level == this.formLevelFilter)
                        value.name = value.first_name + ' ' + value.last_name;
                        return value;
                    })
                }
                this.loadingStudents = false;                
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
        },

        async download () {
            this.loading = true;
            try {
                const { data} = await this.downloadASR();
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([data])
                );
                link.setAttribute('download', '2021-2022 Secondary Schools ASR ver. 2 UPDATED.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            this.loading = false;
            this.overlay = false;
        }, 

        async downloadSpreadsheet () {
            try {
                const { data } = await this.getSpreadsheet();
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([data])
                );
                link.setAttribute('download', `${this.academicPeriod} ${this.selectedFormClass} Class List.xlsx` );
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error)
            }
        } 
    },
   
    
}
</script>