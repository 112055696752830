<template>
     <v-row>           
        <v-col>
            <v-card
                class="pa-0 mx-auto"
                width="950"                
            >
                <v-card-title>
                    Edit / View Students                   
                    
                    <v-spacer></v-spacer>
                    
                    <students-attendance></students-attendance>

                    <v-dialog
                        :value="dialog"
                        max-width="780px"
                        persistent
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                outlined
                                text
                                @click="dialog=true"
                                v-on="on"
                                v-bind="attrs"
                                class="mr-3"
                            >
                                Add New Student
                            </v-btn>                            
                        </template>

                        <students-edit
                            v-if="dialog"                            
                            v-bind:formTitle="formTitle"
                            v-bind:formClasses="formClasses"
                            v-on:close="closeEdit"
                            v-on:update-students="initialize" 
                        ></students-edit>
                             
                    </v-dialog>

                    <StudentsUpload
                        v-on:update-students="initialize"
                    ></StudentsUpload>                    
                    
                </v-card-title>

                <v-card-text>
                    <v-data-table
                        :headers="headers"
                        :items="students"
                        fixed-header                        
                        height="45vh"
                        :calculate-widths="true" 
                        :search="search"
                        :loading="loading"                        
                        item-key="student_id"                                            
                    >
                        <template v-slot:top>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="search"
                                append-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                class="mb-8"
                                clearable
                            ></v-text-field>

                            <v-dialog
                                v-model="dialogDelete"
                                max-width="430px"
                                persistent
                            >
                                <v-card
                                    v-if="confirmDelete"
                                >
                                    <v-card-title>{{ deleteStudentSelected }}</v-card-title>
                                    <v-card-text>                                        
                                        <v-select
                                            :items="statuses"
                                            v-model="status"
                                            label="Status"                                            
                                            item-text="detail"
                                            item-value="id"
                                            autofocus
                                            hide-details
                                            ref="student-status"
                                            clearable
                                            open-on-clear
                                        ></v-select>
                                    </v-card-text>
                                    <v-card-actions
                                        class="px-6"
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="closeDelete"
                                            outlined
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="deleteStudentConfirm"
                                            outlined
                                        >
                                            Delete
                                        </v-btn>                                        
                                    </v-card-actions>
                                </v-card>
                                <v-expand-transition>
                                    <v-card
                                        v-show="deleting"
                                        color="primary"
                                        dark
                                        class="pa-4"
                                    >
                                        <v-card-text>
                                        {{ studentDeleted }}
                                        <v-progress-linear
                                                indeterminate
                                                color="white"
                                                class="mb-0 mt-4"
                                        ></v-progress-linear>
                                        </v-card-text>
                                    </v-card>
                                </v-expand-transition>
                            </v-dialog>
                        </template>

                        <template  v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                tile
                                color="primary"
                                @click="editStudent(item)"
                                class="mr-6"
                                small
                            >
                                Edit/View
                                <v-icon
                                    small
                                    class="ml-3"
                                >
                                    mdi-pencil
                                </v-icon>
                                
                            </v-btn>
                            <v-btn
                                text
                                outlined
                                @click="deleteStudent(item)"
                                small
                            >    
                                Delete
                                <v-icon
                                    small                                    
                                >
                                    mdi-delete
                                </v-icon>
                                
                            </v-btn>    
                        </template> 

                        <template v-slot:item="props">
                            <tr>
                                <td>
                                    <v-img
                                        :src="props.item.picture"
                                        max-width="50"
                                        class="my-4"
                                    >
                                        <template v-slot:placeholder>
                                            <v-row
                                                class="fill-height ma-0"
                                                align="center"
                                                justify="center"
                                            >
                                                <v-progress-circular
                                                    indeterminate
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </td>
                                <td>{{ props.item.student_id }}</td>
                                <td>{{ props.item.name }}</td>
                                <td>{{ props.item.form_class_id }}</td>
                                <td>{{ props.item.gender }}</td>
                                <td>{{ props.item.date_of_birth }}</td>
                                <td>
                                    <v-btn
                                        tile
                                        color="primary"
                                        @click="editStudent(props.item)"
                                        class="mr-6 mb-3"
                                        x-small
                                        block
                                    >
                                        Edit/View
                                        <v-icon
                                            x-small
                                            right
                                        >
                                            mdi-pencil
                                        </v-icon>
                                        
                                    </v-btn>
                                    <v-btn
                                        text
                                        outlined
                                        @click="deleteStudent(props.item)"
                                        x-small
                                        block
                                    >    
                                        Delete
                                        <v-icon
                                            x-small                                    
                                        >
                                            mdi-delete
                                        </v-icon>
                                        
                                    </v-btn>    
                                </td>
                            </tr>
                        </template>                       

                    </v-data-table>

                    <v-snackbar
                        v-model="snack"
                        :timeout="3000"
                        color="primary"
                    >
                        {{ snackText }}
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                v-bind="attrs"
                                text
                                @click="snack = false"
                            >
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                    
                </v-card-text>                
            </v-card>            
        </v-col>
     </v-row>            
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import StudentsEdit from './StudentsEdit';
import StudentsAttendance from './StudentsAttendance.vue';
import StudentsUpload from './StudentsUpload.vue';

export default{ 
    components: {         
        StudentsEdit,
        StudentsAttendance, 
        StudentsUpload,
    },

    created () {
        this.initialize();
    },    

    data: () => ({
        headers: [
            {text: 'Picture', align: 'start', sortable: false, value: 'picture', width: '100'},
            {text: 'ID', align: 'start',  value: 'student_id', width: '100'},
            {text: 'Name', align: 'start', sortable: false, value: 'name', width: '250'},
            {text: 'Class', align: 'start',  value: 'form_class_id', width: '100'},
            {text: 'Gender', align: 'start', sortable: false, value: 'gender', width: '80'},
            {text: 'DOB', align: 'start', value: 'data_of_birth', width: '120'},
            {text: '', align: 'start', sortable: false, value: 'actions', },
        ],
        students: [],
        search: '',
        loading: true,
        snack: false,
        snackText: '',
        options: {
            itemsPerPage: -1,
        },
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
            birth_certificate_pin: '',
            date_of_birth: '',
            first_name: '',
            form_class_id: '',
            form_level: '',
            gender: '',
            last_name: '',
            name: '',
            student_id: '',            
        },
        deletedItem: {
            first_name: '',
            last_name: '',
            date_of_birth: '',
            birth_certificate_pin: '',
            gender: '',
            form_class_id: ''
        },
        defaultItem: {
            birth_certificate_pin: '',
            date_of_birth: '',
            first_name: '',
            form_class_id: '',
            form_level: '',
            gender: '',
            last_name: '',
            name: '',
            student_id: '', 
        },   
        statuses: [],
        status: 3,
        deleteStudentSelected: null,
        confirmDelete: true,
        deleting: false,
        formClasses: [],               
    }),

    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Student' :  
            this.editedItem.first_name + ' '
            + this.editedItem.last_name
        },
        studentDeleted () {
            return 'Deleting ' + this.deletedItem.first_name + ' ' + this.deletedItem.last_name;
        }
    },

    watch: { 
        dialogDelete (val) {
            val || this.closeDelete()
        },
        
        dialog (val) {
            if(val && this.editedIndex == -1){
                this.setNewStudent()
            } 
        }
       
    },

    methods: {
        ...mapActions({
            getStudents: 'common/getCurrentStudents',
            getFormClasses: 'common/getFormClasses',
            getStudentStatus: 'students/getStudentStatus',
            delete: 'students/deleteStudent',            
        }),

        ...mapMutations({
            setEditedStudent: 'students/setEditedStudent',
        }),

        async initialize(){
            try {
                const promiseStudents = this.getStudents();
                const promiseFormClasses = this.getFormClasses();
                const promiseStudentStatus = this.getStudentStatus();

                const [ 
                    { data: students }, 
                    { data: formClasses },
                    { data: dataStudentStatuses}
                ] = 
                await Promise.all([
                    promiseStudents, 
                    promiseFormClasses,
                    promiseStudentStatus
                ]);
                
                this.mapStudents(students);
                this.formClasses = formClasses;
                dataStudentStatuses.forEach(record => {
                    if(record.id > 2){
                        this.statuses.push(record);
                    }
                })
                // console.log(this.statuses);               
                this.loading = false;
            } catch (error) {
                console.log(error);
                console.log(error.response);
            }
        },

        mapStudents (data) {
            this.students = data.map(record => {
                record.name = record.last_name + ', ' + record.first_name;
                record.no_picture = false;
                if(!record.picture){
                    record.picture = require("../assets/icons/student2.svg")
                    record.no_picture = true;
                }
                return record;
            });
        },

        editStudent(item){
            this.setEditedStudent(item);
            this.editedIndex = this.students.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        
        deleteStudent(item){
            //console.log(item);           
            this.deletedItem = Object.assign({}, item);
            this.deleteStudentSelected = item.first_name + ' ' + item.last_name;
            this.dialogDelete = true;
            this.confirmDelete = true;
            //console.log(this.$refs);
            this.$nextTick(() => {
                console.log(this.$refs['student-status'])
                //this.$refs['student-status'].isActive = true;
            })
           
        },
        
        closeDelete(){
            this.dialogDelete = false;
            this.status = 3;            
        },

        async deleteStudentConfirm(){
            console.log('deleting...');
            this.confirmDelete = false;
            setTimeout(() => {
                this.deleting = true;
            });
            
            //console.log(this.deletedItem);
            this.setEditedStudent({
                student_id: this.deletedItem.student_id,
                student_status_id: this.status
            });
            try {
                let response = await this.delete();
                console.log(response);
                const { data } = await this.getStudents();
                this.mapStudents(data);             
                this.deleting = false;                               
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            setTimeout(() =>{
                this.dialogDelete = false;                 
            })
            setTimeout(() => {
                this.snack = true;
                this.snackText = this.deletedItem.first_name + ' ' + this.deletedItem.last_name + ' deleted.'
            })           
            
        },

        closeEdit () {
            this.dialog = false;            
            this.$nextTick( () => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.setEditedStudent(this.editedItem);
                this.editedIndex = -1;
            });            
        },

        setNewStudent () {
            console.log('set edited student')
            this.setEditedStudent(this.editedItem);
        },

        newStudentClick (attrs) {
            console.log(attrs);
        }
    }
}
</script>

<style scoped>
    ::v-deep .v-dialog{
        max-height: 100%
    }
        
    ::v-deep th span{
        display: inline;
    }
</style>